import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'

import winners from '../images/web-marketing-winners.svg'
import celebrate from '../images/celebrate.svg'

const Section = styled.section`
  display: flex;
  max-width: ${props => props.theme.maxwidth};
  flex-wrap: wrap;
  margin: 0 auto;
  .text {
    padding: ${props => props.theme.globalPadding};
    margin-top: 3rem;
  }
`
const WideCol = styled.div`
  flex-grow: 1;
  flex-basis: 400px;
  flex-shrink: 1;
  padding: ${props => props.theme.globalPadding};
`

const Col = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 400px;
  padding: 1rem;
`

const IndexPage = () => (
  <Layout>
    <Header
      title="Celebrate your brand"
      sub="We use the web to help communicate your story"
      image={celebrate}
    />
    <Section style={{ marginTop: '2rem' }}>
      <WideCol>
        <SEO
          title="Digital Marketing Services Wellington - Powerboard Digital Marketing"
          description="We are a full service digital marketing agency based in Wellington New Zealand. Talk to us about how we can help an organisation like yours"
        />
        <h2>Put your best foot forward</h2>
        <p>
          We help business owners find clarity in how they tell their stories,
          so customers feel the same passion for a business's product as their
          owners do. Magic happens when passion is combined with a true
          understanding of the benefit of a product or service.
        </p>
      </WideCol>
    </Section>
    <Section>
      <Col>
        <img src={winners} alt="Digital marketing" />
      </Col>
      <Col>
        <h2>All the tools you need</h2>
        <p>
          We are a full service agency. Whatever you need, we can deliver. From
          design to development, strategy to execution, we have the skills
          needed to get results and give you time to focus on running your
          business, or having a life.
        </p>
      </Col>
    </Section>
  </Layout>
)

export default IndexPage
